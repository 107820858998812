<template>
  <CRow>
    <CCol col>
      <CCard>
        <CCardHeader>
          <CIcon name="cil-justify-center"/>
          <strong> Bootstrap Breadcrumb</strong>
          <div class="card-header-actions">
            <a
              href="https://coreui.io/vue/docs/components/breadcrumb"
              class="card-header-action"
              rel="noreferrer noopener"
              target="_blank"
            >
              <small class="text-muted">docs</small>
            </a>
          </div>
        </CCardHeader>
        <CCardBody>
        <div class="table-responsive">
          <table class="table" >
            <caption>List of users</caption>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Titulo</th>
                <th scope="col">Descripcion</th>
              </tr>
            </thead>
            <tbody v-for="(post, p) in posts">
              <tr>
                <th scope="row">{{p+1}}</th>
                <td>{{post.title}}</td>
                <td>{{post.body}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import axios from 'axios'
export default{
  name: 'Inicio',
  mounted(){
    this.getPost()
  },
  data(){
    return{
      posts:[]
    }
  },
  methods:{
    getPost(){
      let vue=this;
      axios.get('https://jsonplaceholder.typicode.com/posts')
          .then( function(response){
             vue.posts=response.data;
            //console.log(vue.posts);
            })
    }
  }
}
</script>
<style>
.post{
  border: 1px solid #D9D9D9;
  padding: 20px;
  margin-bottom: 10px;
}
</style>